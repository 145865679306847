import React from 'react';

interface TextInputTypes {
	inputId: string;
	label: string;
	isError?: boolean;
	value: string;
	onChangeFunction: any;
	placeholder: string;
	isNumeric?: boolean;
	isRequired?: boolean;
	disabled?: boolean;
}

const TextInput = ({
	inputId,
	label,
	isError,
	value,
	onChangeFunction,
	placeholder,
	isNumeric,
	isRequired,
	disabled,
}: TextInputTypes) => {
	return (
		<>
			<label
				htmlFor="agent-id-number"
				className="mb-[6px] flex text-left text-[12px] font-medium tracking-wider	"
			>
				{label}
				{isRequired ? <span className="text-red-700	">*</span> : ''}
			</label>
			{inputId === 'phone' ? (
				<span className="pointer-events-none absolute ml-[21px] mt-[8.5px]">
					+1
				</span>
			) : (
				''
			)}
			<input
				maxLength={inputId === 'phone' ? 12 : undefined}
				id={inputId}
				name={inputId}
				type={isNumeric ? 'number' : 'text'}
				placeholder={placeholder}
				className={`
        ${inputId === 'phone' ? 'pl-[40px]' : ''}
        ${
			isError
				? 'border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
				: disabled
				? 'bg-gray outline-none'
				: 'bg-white outline-none'
		}
                  w-full rounded px-5 py-2 transition disabled:cursor-default`}
				disabled={disabled}
				value={value}
				onChange={onChangeFunction}
				onKeyDown={(evt) => {
					if (!isNumeric) {
						return;
					}
					['e', 'E', '+', '-'].includes(evt.key) &&
						evt.preventDefault();
				}}
			/>
		</>
	);
};

export default TextInput;
