import { Link } from 'react-router-dom';

import Logo from '../images/branding-white.svg';
import ScenariosDropdown from '../pages/TestingTool/ScenariosDropdown';
import { Scenario } from '../pages/Interfaces';
interface HeaderProps {
	options: Scenario[];
	onChangeOption: (option: Scenario) => void;
	resetForm: () => void;
	trialToken: string;
	isActiveTrial: boolean;
	trialExpiryDate: Date;
}
const Header = ({
	options,
	onChangeOption,
	resetForm,
	trialToken,
	isActiveTrial,
	trialExpiryDate,
}: HeaderProps) => {
	const trialDaysLeft = Math.round(
		(trialExpiryDate.getTime() - new Date().getTime()) / 86400000
	);

	return (
		<header className="header sticky top-0 z-50 flex h-[5rem] items-center">
			<div className="flex flex-grow items-center justify-between px-6 2xl:pl-12">
				<div className="flex items-center">
					<Link
						to={'/token/' + trialToken}
						className="logo hidden lg:block"
					>
						<img src={Logo} alt="Logo" />
					</Link>
				</div>
			</div>
			<div hidden={!isActiveTrial} className={`mr-[24px] text-white`}>
				You have{' '}
				{isActiveTrial && trialDaysLeft < 1
					? 'less than 1 day'
					: trialDaysLeft == 1
					? '1 day'
					: trialDaysLeft > 1
					? trialDaysLeft + ' days'
					: ''}{' '}
				left in your free trial.
			</div>
			<div hidden={!isActiveTrial}>
				<ScenariosDropdown
					options={options}
					onChangeOption={onChangeOption}
					resetForm={resetForm}
				/>
			</div>
		</header>
	);
};

export default Header;
