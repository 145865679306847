import Loader from '../../images/loader-gif.gif';

const LoadingOverlay = () => {
	return (
		<>
			<div className="loading-overlay absolute inset-0 z-40 flex">
				<div className="m-auto">
					<img src={Loader} alt="loading" className="m-auto" />
				</div>
			</div>
		</>
	);
};

export default LoadingOverlay;
