// Style values can be either

interface titleTailwindClassOptions {
	color?: string;
	display?: string;
	fontSize?: string;
	fontStyle?: string;
	fontWeight?: string;
	lineHeight?: string;
	letterSpacing?: string;
	marginBottom?: string;
}

interface valueTailwindClassOptions {
	color?: string;
	fontSize?: string;
	fontStyle?: string;
	fontWeight?: string;
	lineHeight?: string;
	opacity?: string;
}

interface DealModalGridItemProps {
	fieldTitle: string;
	fieldValue: string;
	containerClasses?: string;
	titleTailwindClassOptions?: titleTailwindClassOptions;
	titleExtraClasses?: string;
	valueTailwindClassOptions?: valueTailwindClassOptions;
	valueExtraClasses?: string;
}

const DealModalGridItem = ({
	fieldTitle,
	fieldValue,
	containerClasses = '',
	titleTailwindClassOptions = {},
	titleExtraClasses = '',
	valueTailwindClassOptions = {},
	valueExtraClasses = '',
}: DealModalGridItemProps) => {
	const defaultTitleTailwindClasses: titleTailwindClassOptions = {
		color: 'text-[#111827]',
		display: 'block',
		fontSize: 'text-[12px]',
		fontStyle: 'not-italic',
		fontWeight: 'font-[700]',
		lineHeight: 'leading-normal',
		letterSpacing: 'tracking-[0.24px]',
		marginBottom: 'mb-[8px]',
	};
	const defaultValueTailwindClasses: valueTailwindClassOptions = {
		color: 'text-[#111827]',
		fontSize: 'text-[20px]',
		fontStyle: 'not-italic',
		fontWeight: 'font-[450]',
		lineHeight: 'leading-normal',
		opacity: 'opacity-80',
	};

	const titleStylingFinal = {
		...defaultTitleTailwindClasses,
		...titleTailwindClassOptions,
	};
	const valueStylingFinal = {
		...defaultValueTailwindClasses,
		...valueTailwindClassOptions,
	};

	return (
		<div className={`${containerClasses}`}>
			<span
				className={`${Object.values(titleStylingFinal).join(
					' '
				)} ${titleExtraClasses}`}
			>
				{fieldTitle}
			</span>
			<span
				className={`${Object.values(valueStylingFinal).join(
					' '
				)} ${valueExtraClasses}`}
			>
				{fieldValue}
			</span>
		</div>
	);
};

export default DealModalGridItem;
