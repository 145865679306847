import type { ReactNode } from 'react';

import Footer from './Footer';
import Header from './Header';
import { Scenario } from '../pages/Interfaces';

interface OnboardingLayoutProps {
	children: ReactNode;
	options: Scenario[];
	onChangeOption: (option: Scenario) => void;
	resetForm: () => void;
	trialToken: string;
	isActiveTrial: boolean;
	trialExpiryDate: Date;
}

const OnboardingLayout = ({
	children,
	options,
	onChangeOption,
	resetForm,
	trialToken,
	isActiveTrial,
	trialExpiryDate,
}: OnboardingLayoutProps) => {
	return (
		<div id="wrapper">
			<Header
				options={options}
				onChangeOption={onChangeOption}
				resetForm={resetForm}
				trialToken={trialToken}
				isActiveTrial={isActiveTrial}
				trialExpiryDate={trialExpiryDate}
			/>
			<div className="main-content relative flex bg-sidebar-bk">
				<main className="flex flex-1 justify-center bg-white pb-24 pl-8 pr-4 pt-14 md:pl-10 md:pr-6">
					{children}
				</main>
			</div>
			<Footer />
		</div>
	);
};

export default OnboardingLayout;
