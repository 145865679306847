import { AiOutlineInstagram, AiOutlineLinkedin } from 'react-icons/ai';
import { FiTwitter } from 'react-icons/fi';
import { RiFacebookCircleLine } from 'react-icons/ri';

const Footer = () => {
	return (
		<footer className="footer flex h-[7rem] flex-col items-center justify-between px-4 py-4 text-white md:px-6 lg:flex-row lg:py-0 2xl:px-12">
			<div className="flex flex-col gap-2 md:flex-row md:gap-8">
				<p className="hidden md:block">Copyright © Carbeeza 2024</p>
				<ul className="flex gap-5 space-x-5 text-[#DED4FF]">
					<li>
						<a
							className="break-words underline decoration-[#B49FFF] underline-offset-4"
							href="https://www.ultralead.ai/s/UltraLeadSLA.pdf "
							target="_blank"
						>
							Service Level Agreement
						</a>
					</li>
					<li>
						<a
							className="break-words underline decoration-[#B49FFF] underline-offset-4"
							href="https://carbeeza.com/dealer-terms-of-service"
							target="_blank"
						>
							Terms of Service
						</a>
					</li>
					<li>
						<a
							className="break-words underline decoration-[#B49FFF] underline-offset-4"
							href="https://carbeeza.com/privacy-policy"
							target="_blank"
						>
							Privacy Policy
						</a>
					</li>
				</ul>
				<p className="hidden md:block">
					<span className="mr-1 text-[#FFD609]">TSXV:</span>AUTO
				</p>
			</div>
			<div className="mt-2 flex xs:flex-row lg:flex-col">
				<p>
					<span className="mb-1 text-xs text-[#FFD609] xs:mr-2">
						Follow us on:
					</span>
				</p>
				<ul className="flex gap-4">
					<li>
						<a
							href="https://www.instagram.com/carbeeza/"
							target="_blank"
						>
							<AiOutlineInstagram className="h-5 w-5" />
						</a>
					</li>
					<li>
						<a
							href="https://www.facebook.com/Carbeeza/"
							target="_blank"
						>
							<RiFacebookCircleLine className="h-5 w-5" />
						</a>
					</li>
					<li>
						<a href="https://twitter.com/Carbeeza" target="_blank">
							<FiTwitter className="h-5 w-5" />
						</a>
					</li>
					<li>
						<a
							href="http://linkedin.com/company/carbeeza"
							target="_blank"
						>
							<AiOutlineLinkedin className="h-5 w-5" />
						</a>
					</li>
				</ul>
			</div>
		</footer>
	);
};

export default Footer;
