import React from 'react';

interface MoneyInputTypes {
	inputId: string;
	label: string;
	isError?: boolean;
	value: string;
	onChangeFunction: any;
	country: string;
	disabled?: boolean;
}

const MoneyInput = ({
	inputId,
	label,
	value,
	onChangeFunction,
	country,
	disabled,
}: MoneyInputTypes) => {
	return (
		<>
			<label
				htmlFor={label}
				className="mb-[6px] flex text-left text-[12px] font-medium tracking-wider	"
			>
				{label}
			</label>
			<span
				className="absolute ml-[13px] mt-2 text-lg font-light "
				id="basic-addon1"
			>
				$
			</span>
			<input
				id={inputId}
				name={inputId}
				type="number"
				placeholder=""
				className={`w-full rounded px-6 py-2 outline-none transition disabled:cursor-default 
                [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none
				${disabled ? 'bg-gray' : 'bg-white'}`}
				disabled={disabled}
				value={value}
				onChange={onChangeFunction}
				onKeyDown={(evt) =>
					['e', 'E', '+', '-'].includes(evt.key) &&
					evt.preventDefault()
				}
			/>
			<span className="absolute -ml-12 mt-2 text-lg font-light text-[#909090]	">
				{!country ? '' : `${country}D`}
			</span>
		</>
	);
};

export default MoneyInput;
