import { useState, useEffect, useRef } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import type { Scenario } from '../Interfaces';

interface DropdownProps {
	options: Scenario[];
	onChangeOption: (option: Scenario) => void;
	resetForm: () => void;
}

const ScenariosDropdown = ({
	options,
	onChangeOption,
	resetForm,
}: DropdownProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState<Scenario>({
		name: 'Select Sales Scenario',
	});
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [dropdownPosition, setDropdownPosition] = useState<'above' | 'below'>(
		'below'
	);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (option: Scenario) => {
		setSelectedOption(option);
		setIsOpen(false);

		onChangeOption(option);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current?.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	useEffect(() => {
		if (dropdownRef.current) {
			const dropdownRect = dropdownRef.current.getBoundingClientRect();
			const windowHeight = window.innerHeight;

			if (dropdownRect.bottom + 200 > windowHeight) {
				setDropdownPosition('above');
			} else {
				setDropdownPosition('below');
			}
		}
	}, [isOpen]);

	return (
		<div className="flex  flex-grow items-center justify-between">
			<div className="relative" ref={dropdownRef}>
				<div
					className="mr-[48px] w-[285px] cursor-pointer items-center rounded-full border border-paragraph/60 bg-[#F1EDFF] px-[22px] py-2 transition duration-300 ease-in-out"
					onClick={toggleDropdown}
					id="scenario-dropdown"
				>
					<span className="mr-1">{selectedOption?.name}</span>
					{isOpen ? (
						<BiChevronUp className="absolute right-[65px] top-[12px] h-5 w-5 transition duration-300 ease-in-out" />
					) : (
						<BiChevronDown className="absolute right-[65px] top-[12px] h-5 w-5 transition duration-300 ease-in-out" />
					)}
				</div>
				{isOpen && (
					<div
						className={`dropdown-content absolute z-10 my-1 max-h-[80vh] w-full overflow-auto rounded border-2 bg-white p-5 transition duration-300 ease-in-out ${
							dropdownPosition === 'above'
								? 'bottom-full'
								: 'top-full'
						}`}
					>
						{options.map((option) => (
							<div
								key={option.name}
								className={`analytics-scenario-dropdown-option block p-2 text-paragraph ${
									selectedOption.name == option.name
										? 'bg-sidebar-bk font-medium'
										: ''
								} cursor-pointer hover:bg-sidebar-bk`}
								onClick={() => handleOptionClick(option)}
							>
								{option.name}
							</div>
						))}
					</div>
				)}
			</div>

			<div className="z-50 ml-[-24px] mr-[24px]">
				<a
					className={`cursor-pointer text-white underline`}
					onClick={() => {
						resetForm();
						setSelectedOption({
							name: 'Select Sales Scenario',
						});
					}}
				>
					Reset
				</a>
			</div>
		</div>
	);
};

export default ScenariosDropdown;
