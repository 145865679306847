import {
    MdFirstPage,
    MdLastPage,
    MdNavigateBefore,
    MdNavigateNext,
} from 'react-icons/md';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import ReactImageFallback from 'react-image-fallback';
import { Tooltip } from 'react-tooltip';

import DealerPlaceholder from '../../images/dealer-placeholder.png';
import type { DealResponse, Programs } from '../Interfaces';

import { ERRORS } from './DealHUD';

interface TableProps<T extends object> {
    data?: Programs[];
    country?: string;
    maxMonthly?: number;
    deal?: DealResponse;
    errorCodes?: string[];
}

const LendersTable = <T extends Object>({
    data,
    country,
    maxMonthly,
    deal,
    errorCodes,
}: TableProps<T>) => {
    const USDollar = new Intl.NumberFormat('en-US', {
        currency: 'USD',
        style: 'currency',
    });
    // Render the UI for your table and the styles
    return (
        <div className="lenders-table overflow-x-auto rounded-[4px] bg-white">
            <table className="min-w-full whitespace-nowrap text-left text-sm">
                <thead className="rounded-t-md border-b-[1px] border-[#A285FF] bg-[#EFEAFF] text-[16px] tracking-wider text-[#3B2F66]">
                    <tr>
                        <th scope="col" className="max-w-[175px] px-5 py-4">
                            Lender Name
                            <div className="inline">
                                <svg
                                    width="11"
                                    height="16"
                                    viewBox="0 0 11 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-3 inline"
                                >
                                    <g id="sort icon">
                                        <path
                                            id="icons/pagination-right-single"
                                            d="M1.5 5.01562L5.69048 1.01562L9.5 5.01562"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            id="icons/pagination-right-single_2"
                                            d="M9.5 10.0157L5.30952 14.0157L1.5 10.0157"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </th>
                        <th
                            scope="col"
                            className="max-w-[175px] px-[15px] py-4"
                        >
                            Lender Program
                            <div className="inline">
                                <svg
                                    width="11"
                                    height="16"
                                    viewBox="0 0 11 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-3 inline"
                                >
                                    <g id="sort icon">
                                        <path
                                            id="icons/pagination-right-single"
                                            d="M1.5 5.01562L5.69048 1.01562L9.5 5.01562"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            id="icons/pagination-right-single_2"
                                            d="M9.5 10.0157L5.30952 14.0157L1.5 10.0157"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </th>
                        <th scope="col" className="px-5 py-4">
                            Rate
                            <div className="inline">
                                <svg
                                    width="11"
                                    height="16"
                                    viewBox="0 0 11 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-3 inline"
                                >
                                    <g id="sort icon">
                                        <path
                                            id="icons/pagination-right-single"
                                            d="M1.5 5.01562L5.69048 1.01562L9.5 5.01562"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            id="icons/pagination-right-single_2"
                                            d="M9.5 10.0157L5.30952 14.0157L1.5 10.0157"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </th>
                        <th scope="col" className="px-5 py-4">
                            Term
                            <div className="inline">
                                <svg
                                    width="11"
                                    height="16"
                                    viewBox="0 0 11 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-3 inline"
                                >
                                    <g id="sort icon">
                                        <path
                                            id="icons/pagination-right-single"
                                            d="M1.5 5.01562L5.69048 1.01562L9.5 5.01562"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            id="icons/pagination-right-single_2"
                                            d="M9.5 10.0157L5.30952 14.0157L1.5 10.0157"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </th>
                        <th scope="col" className="px-5 py-4">
                            ProgramType
                            <div className="inline">
                                <svg
                                    width="11"
                                    height="16"
                                    viewBox="0 0 11 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-3 inline"
                                >
                                    <g id="sort icon">
                                        <path
                                            id="icons/pagination-right-single"
                                            d="M1.5 5.01562L5.69048 1.01562L9.5 5.01562"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            id="icons/pagination-right-single_2"
                                            d="M9.5 10.0157L5.30952 14.0157L1.5 10.0157"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </th>
                        <th scope="col" className="px-5 py-4">
                            Estimated Monthly
                            <div className="inline">
                                <svg
                                    width="11"
                                    height="16"
                                    viewBox="0 0 11 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-3 inline"
                                >
                                    <g id="sort icon">
                                        <path
                                            id="icons/pagination-right-single"
                                            d="M1.5 5.01562L5.69048 1.01562L9.5 5.01562"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            id="icons/pagination-right-single_2"
                                            d="M9.5 10.0157L5.30952 14.0157L1.5 10.0157"
                                            stroke="#9270FF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </th>
                        <th scope="col" className="px-5 py-4"></th>
                    </tr>
                </thead>

                <tbody>
                    {data?.length
                        ? data?.map((program, idx) => {
                              return (
                                  <tr
                                      className={`border-b border-[#C1B7DB] ${
                                          idx === 0
                                              ? 'bg-[#F1FFF7]'
                                              : maxMonthly &&
                                                program.estimated_monthly >
                                                    maxMonthly
                                              ? '!bg-[#FFF8F8]'
                                              : ''
                                      }`}
                                      key={idx + program?.lender}
                                  >
                                      <td className="flex max-w-[175px] items-center gap-[20px] overflow-hidden text-ellipsis py-4 pl-6 pr-[15px]	">
                                          <ReactImageFallback
                                              src={`/images/lender-logos/${program?.lender?.trim()}.png`}
                                              fallbackImage={DealerPlaceholder}
                                              alt={`${program?.lender}-logo`}
                                              className="lender-logo-container h-[32px] w-[32px]"
                                          />
                                          <a
                                              data-tooltip-id={`${idx}-lender-anchor-element`}
                                              className="overflow-hidden text-ellipsis"
                                          >
                                              {program?.lender
                                                  ? program.lender
                                                  : 'N/A'}
                                          </a>
                                          <Tooltip
                                              id={`${idx}-lender-anchor-element`}
                                              className="lender-tooltip"
                                              place="top"
                                              style={{
                                                  background: 'white',
                                                  borderColor: '#EFEAFF',
                                                  borderWidth: '3px',
                                                  color: '#464B4F',
                                                  fontSize: '16px',
                                              }}
                                          >
                                              {program?.lender}
                                          </Tooltip>
                                      </td>
                                      <td className="max-w-[175px] overflow-hidden text-ellipsis px-[15px] py-4">
                                          <a
                                              data-tooltip-id={`${idx}-program-anchor-element`}
                                          >
                                              {program?.program
                                                  ? program.program
                                                  : 'N/A'}
                                          </a>
                                          <Tooltip
                                              id={`${idx}-program-anchor-element`}
                                              className="lender-tooltip"
                                              place="top"
                                              style={{
                                                  background: 'white',
                                                  borderColor: '#EFEAFF',
                                                  borderWidth: '3px',
                                                  color: '#464B4F',
                                                  fontSize: '16px',
                                              }}
                                          >
                                              {program?.program}
                                          </Tooltip>
                                      </td>
                                      <td className="px-5 py-4">
                                          {program?.rate
                                              ? program.rate + ' %'
                                              : 'N/A'}
                                      </td>
                                      <td className="px-5 py-4">
                                          {program?.term ? program.term : 'N/A'}
                                      </td>
                                      <td className="px-5 py-4">{'N/A'}</td>
                                      <td
                                          className={`px-5 py-4 ${
                                              maxMonthly &&
                                              program.estimated_monthly >
                                                  maxMonthly
                                                  ? 'text-[#FF6767]'
                                                  : idx === 0
                                                  ? 'text-[#008037]'
                                                  : ''
                                          }`}
                                      >
                                          {program?.estimated_monthly
                                              ? `${USDollar.format(
                                                    program.estimated_monthly
                                                )} ${country}D`
                                              : 'N/A'}
                                      </td>
                                      <td className="px-5 py-4">
                                          {errorCodes?.includes(
                                              ERRORS.NON_FINANCIBLE
                                          ) ||
                                          errorCodes?.includes(
                                              ERRORS.AMOUNT_TOO_SMALL
                                          ) ? (
                                              <div>
                                                  <a
                                                      data-tooltip-id={`${idx}-error-anchor-element`}
                                                  >
                                                      <RiMoneyDollarCircleLine
                                                          color="#FF6767"
                                                          size={20}
                                                      />
                                                  </a>
                                                  <Tooltip
                                                      id={`${idx}-error-anchor-element`}
                                                      className="error-tooltip"
                                                      place="top-start"
                                                      border="3px solid #FF6767"
                                                      style={{
                                                          background: '#FFF2F2',
                                                          color: '#FF6767',
                                                          fontSize: '16px',
                                                          height: '120px',
                                                          padding: '8px',
                                                          width: '238px',
                                                      }}
                                                  >
                                                      <p className="w-full whitespace-break-spaces text-center">
                                                          {errorCodes?.includes(
                                                              ERRORS.NON_FINANCIBLE
                                                          )
                                                              ? `Please Note: Lender requires a minimum downpayment of ${USDollar.format(
                                                                    deal?.min_downpayment ||
                                                                        0
                                                                )} ${country}D from this customer. `
                                                              : `Please Note: Lender requires a maximum downpayment of ${USDollar.format(
                                                                    deal?.max_downpayment ||
                                                                        0
                                                                )} ${country}D from this customer. `}
                                                      </p>
                                                  </Tooltip>
                                              </div>
                                          ) : errorCodes?.includes(
                                                ERRORS.AMOUNT_TOO_SMALL
                                            ) ? (
                                              <div className="over-tag">
                                                  <div className="over-tag-inner text-center text-[10px] font-bold text-white">
                                                      OVER
                                                  </div>
                                              </div>
                                          ) : (
                                              ''
                                          )}
                                      </td>
                                  </tr>
                              );
                          })
                        : ''}
                </tbody>
            </table>
            <div className="flex items-center justify-between">
                <div className="flex flex-col items-center justify-start px-6 pb-3 pt-5 text-paragraph md:flex-row">
                    <span>
                        Viewing 1-{data?.length} of {data?.length} results
                    </span>
                </div>
                <div className="flex flex-col items-center justify-end px-5 pb-3 pt-5 text-paragraph md:flex-row">
                    <span className="md:mr-8">Rows per page</span>
                    <select>
                        <option>20</option>
                    </select>

                    <div className="pagination -button-group flex gap-4 md:ml-12">
                        <button className="disabled:text-gray">
                            <MdFirstPage className="h-6 w-6" />
                        </button>
                        <button className="disabled:text-gray">
                            <MdNavigateBefore className="h-6 w-6" />
                        </button>
                        <button className="disabled:text-gray">
                            <MdNavigateNext className="h-6 w-6" />
                        </button>
                        <button className="disabled:text-gray">
                            <MdLastPage className="h-6 w-6" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LendersTable;
