import axios from 'axios';

import type {
	TargetVehicleRequest,
	ValidateTrialRequest,
	ValidateTrialResponse,
} from '../pages/Interfaces';

import { decisionEngine, devAPI, targetVehicleAPI } from './constants';

export const createLead = async (params: any) => {
	const res = await axios.post(devAPI + '/create-lead', {
		...params,
	});
	return res;
};

export const calculate = async (params: any) => {
	const res = await axios.post(decisionEngine, {
		...params,
	});
	return res;
};

export const searchTarget = async (params: TargetVehicleRequest) => {
	const res = await axios.get(targetVehicleAPI + '/vehicle-search', {
		params: params,
	});
	return res;
};

export const validateTrialToken = async (params: ValidateTrialRequest) => {
	const res = await axios.post(targetVehicleAPI + '/validate-trial', params);
	return res;
};

export const getScenarios = async () => {
	const res = await axios.get(targetVehicleAPI + '/scenarios');
	return res;
};
