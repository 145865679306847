import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import TestingTool from './pages/TestingTool';

import './App.css';

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate replace to="/token/0" />} />
				<Route path="/token/:trialToken" element={<TestingTool />} />
				<Route
					path="/token/"
					element={<Navigate replace to="/token/0" />}
				/>
				<Route path="*" element={<Navigate replace to="/token/0" />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
