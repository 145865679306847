import Modal from '../Modal';

interface DealershipRegisteredModalProps {
	setModalOpen: (open: boolean) => void;
}
const SuccessModal = ({ setModalOpen }: DealershipRegisteredModalProps) => {
	return (
		<Modal
			isOpen={true}
			onClose={() => setModalOpen(false)}
			children={
				<div className="flex h-[400px] flex-col	">
					<h2 className="mb-8 text-3xl text-paragraph">
						Registration Successful
					</h2>
					<p className="text-paragraph">
						Please keep an eye on your inbox for your verification
						email. Follow the link provided to confirm your new
						account and activate your free trial.
						<br />
						<br />
						We look forward to working with you!
						<br />
						<br />
						- Team Carbeeza
						<br />
						<br />
					</p>
					<div className="text-paragraph">
						P.S. If you do not receive an email within 24 hours,
						please contact us at{' '}
						<a
							href="mailto:support@carbeeza.com"
							className="text-primary hover:underline"
						>
							support@carbeeza.com
						</a>
					</div>
					<div className="mt-[100px]">
						<div className="ml-auto flex justify-end">
							<button
								type="submit"
								className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
								onClick={() => window.location.reload()}
							>
								Done
							</button>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default SuccessModal;
