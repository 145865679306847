import Modal from '../../components/Modal';

interface ErrorModalProps {
	setModalOpen?: (open: boolean) => void;
	response?: string;
}
const TradeInModal = ({ setModalOpen, response }: ErrorModalProps) => {
	return (
		<Modal
			isOpen={true}
			onClose={() => console.log(false)}
			title={
				<h2 className="text-[32px] text-paragraph">
					Add trade-in to deal
				</h2>
			}
			children={
				<>
					<div className="grid-col grid grid-cols-2 gap-[28px]">
						<div className="col-span-2">
							<hr className="h-0.5 w-full border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
						</div>
						<div className="col-span-2 px-[25px]">
							<p className="text-center text-[20px] text-[#463978]">
								Search for a vehicle to add to the deal by year,
								make, model and trim level or VIN number. Once
								the correct vehicle is targeted, click “Add
								Trade” to include the trade in your deal.
								<br />
								<br />
								Please note that all trade-in values are
								estimates only, and the true value will be
								finalized upon inspection.
							</p>
						</div>
						<div className="col-span-2">
							<hr className="h-0.5 w-full border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
						</div>
						<div className="col-span-2">
							<span className="text-[20px] font-[500] text-[#3B2F66]">
								Add trade by make, model & trim
							</span>
						</div>
						<div className="sm:col-span-2 md:col-span-1">
							<label
								htmlFor="year-label"
								className="mb-[6px] flex text-left text-xs font-medium"
							>
								Year
							</label>
							<select
								id="year"
								className={
									'border-1 w-full rounded border-[#8874C3] bg-white px-5  py-2 outline-none transition disabled:cursor-default'
								}
							>
								<option selected>Select Year</option>
								<option value="2024">2024</option>
								<option value="2022">2022</option>
								<option value="2023">2023</option>
								<option value="2021">2021</option>
								<option value="2020">2020</option>
								<option value="2019">2019</option>
								<option value="2018">2018</option>
								<option value="2017">2017</option>
								<option value="2016">2016</option>
								<option value="2015">2015</option>
							</select>
						</div>
						<div className="sm:col-span-2 md:col-span-1">
							<label
								htmlFor="make-label"
								className="mb-[6px] flex text-left text-xs font-medium"
							>
								Make
							</label>
							<select
								id="make"
								className={
									'border-1 w-full rounded border-[#8874C3] bg-white px-5  py-2 outline-none transition disabled:cursor-default'
								}
							>
								<option selected>Select Make</option>
								<option value="2024">2024</option>
								<option value="2022">2022</option>
								<option value="2023">2023</option>
								<option value="2021">2021</option>
								<option value="2020">2020</option>
								<option value="2019">2019</option>
								<option value="2018">2018</option>
								<option value="2017">2017</option>
								<option value="2016">2016</option>
								<option value="2015">2015</option>
							</select>
						</div>
						<div className="sm:col-span-2 md:col-span-1">
							<label
								htmlFor="model-label"
								className="mb-[6px] flex text-left text-xs font-medium"
							>
								Model
							</label>
							<select
								id="model"
								className={
									'border-1 w-full rounded border-[#8874C3] bg-white px-5  py-2 outline-none transition disabled:cursor-default'
								}
							>
								<option selected>Select Model</option>
								<option value="2024">2024</option>
								<option value="2022">2022</option>
								<option value="2023">2023</option>
								<option value="2021">2021</option>
								<option value="2020">2020</option>
								<option value="2019">2019</option>
								<option value="2018">2018</option>
								<option value="2017">2017</option>
								<option value="2016">2016</option>
								<option value="2015">2015</option>
							</select>
						</div>
						<div className="sm:col-span-2 md:col-span-1">
							<label
								htmlFor="trim-label"
								className="mb-[6px] flex text-left text-xs font-medium"
							>
								Trim
							</label>
							<select
								id="trim"
								className={
									'border-1 w-full rounded border-[#8874C3] bg-white px-5  py-2 outline-none transition disabled:cursor-default'
								}
							>
								<option selected>Select Trim</option>
								<option value="2024">2024</option>
								<option value="2022">2022</option>
								<option value="2023">2023</option>
								<option value="2021">2021</option>
								<option value="2020">2020</option>
								<option value="2019">2019</option>
								<option value="2018">2018</option>
								<option value="2017">2017</option>
								<option value="2016">2016</option>
								<option value="2015">2015</option>
							</select>
						</div>
						<div className="sm:col-span-2 md:col-span-1">
							<label
								htmlFor="agent-id-number"
								className="mb-[6px] flex text-left text-xs font-medium"
							>
								Mileage
							</label>
							<input
								id="mileage"
								name="mileage"
								type="number"
								placeholder=""
								className="w-full rounded bg-white px-6 py-2 outline-none transition disabled:cursor-default 
                [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
								// value={value}
								// onChange={onChangeFunction}
								// onKeyDown={(evt) =>
								//   ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
								// }
							/>
							<span className="absolute -ml-[55px] mt-[13px] text-[12px] font-light text-[#000]	">
								MILES
							</span>
						</div>
						<div></div>
						<div className="col-span-2">
							<hr className="h-0.5 w-full border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
						</div>
						<div className="col-span-2">
							<span className="text-[20px] font-[500] text-[#3B2F66]">
								Add trade by make, model & trim
							</span>
							<input
								id="vin"
								name="vin"
								type="text"
								placeholder="Enter VIN"
								className="mt-[8px] w-full rounded bg-white px-6  py-2 outline-none transition 
                [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
								// className={`${
								//   isError
								//     ? 'border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error'
								//     : 'bg-white outline-none'
								// }
								//           w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
								//       `}
								// value={value}
								// onChange={onChangeFunction}
							/>
						</div>
						<div className="col-span-2">
							<hr className="h-0.5 w-full border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
						</div>
						<div>
							<span className="text-[20px] font-[500] text-[#3B2F66]">
								Results
							</span>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default TradeInModal;
