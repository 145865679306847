import type { ReactNode } from 'react';
import { useState, useEffect } from 'react';
import { CgClose } from 'react-icons/cg';
interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	children: ReactNode;
	title?: ReactNode;
}

const Modal = ({ isOpen, onClose, children, title }: ModalProps) => {
	const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	useEffect(() => {
		if (isOpen) {
			setIsOverlayVisible(true);
			document.addEventListener('click', handleOutsideClick);
		} else {
			setIsOverlayVisible(false);
			document.removeEventListener('click', handleOutsideClick);
		}
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [isOpen]);

	const handleOutsideClick = (event: MouseEvent) => {
		event.stopPropagation();
		const modal = document.querySelector('.modal-wrapper.block .modal');
		if (modal && !modal.contains(event.target as Node) && !isOpen) {
			onClose();
		}
	};

	return (
		<>
			{isOverlayVisible && (
				<div className="fixed inset-0 z-40 bg-[#120E23B0] opacity-75"></div>
			)}

			<div
				className={`modal-wrapper fixed inset-0 z-50 overflow-y-auto ${
					isOpen ? 'block' : 'hidden'
				}`}
			>
				<div className="flex min-h-screen items-center justify-center">
					<div className="modal transform overflow-hidden rounded-3xl bg-white px-[36px] py-[40px] shadow-xl transition-all sm:max-w-[50rem] md:w-full md:max-w-[1183px]">
						<div className="flex justify-between">
							<div>{title}</div>
							<div
								onClick={onClose}
								className="flex cursor-pointer flex-nowrap items-center"
							>
								<span className="-mt-[3px] mr-[20px] text-[18px] text-paragraph underline hover:text-paragraph/60">
									Close
								</span>
								<button className="text-paragraph hover:text-paragraph/60">
									<svg
										width="15"
										height="16"
										viewBox="0 0 15 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											id="Vector"
											d="M1.5 2.01569L13.5 14.0157M13.5 2.01569L1.5 14.0157"
											stroke="#3B2F66"
											strokeWidth="3"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
							</div>
						</div>
						<div className="modal-body mt-[28px]">{children}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Modal;
