import Modal from '../Modal';
import TrialHero from '../../images/test-tool-trial-image-1.png';

interface IntroModalProps {
	setModalOpen: (open: boolean) => void;
}
const IntroModal = ({ setModalOpen }: IntroModalProps) => {
	return (
		<Modal
			isOpen={true}
			onClose={() => setModalOpen(false)}
			title={
				<h2 className="text-[32px] text-paragraph">
					Welcome to UltraLead Lite
				</h2>
			}
			children={
				<>
					<div className="grid-col grid grid-cols-2 gap-[28px]">
						<div className="col-span-2">
							<img
								src={TrialHero}
								alt="Zombie images with text Deals Come Alive with Ultralead"
							/>
						</div>
						<div className="col-span-2">
							<h3 className="pb-[25px] text-2xl text-black">
								Thanks for coming! We're dying to show you
								around.
							</h3>
							<p className="py-[10px] text-[20px] text-black">
								This platform is designed to demonstrate the
								core functionalities of UltraLead without the
								need to integrate. Simply use the fields on the
								following page to create a lead scenario and
								watch Ultralead bring it to life with a credit
								prediction, alternative vehicle options, and
								matching lender programs. Come back to this URL
								for easy access throughout your trial.
							</p>
							<h3 className="py-[25px] text-2xl text-black">
								Ready to Level Up?{' '}
								<a
									href="https://www.ultralead.ai/#packages"
									className="text-[#9475FF] hover:underline"
									target="_blank"
									rel="noreferrer noopener"
								>
									Upgrade your toolkit.
								</a>
							</h3>
							{/* <p className="py-[10px] text-[20px] text-black">
                                For the full UltraLead experience, including
                                integration into your existing lead funnel and
                                complete access to all features, Consider an
                                integrated trial of our full version for the
                                complete experience. Contact us at{' '}
                                <a
                                    className="text-[#9475FF] hover:underline"
                                    href="mailto:support@carbeeza.com"
                                >
                                    support@carbeeza.com
                                </a>{' '}
                                to get started.
                            </p>
                            <p className="py-[10px] text-[20px] text-black">
                                P.S. Remember to bookmark this URL for easy
                                access throughout your trial.
                            </p> */}
							<p className="py-[10px] text-[20px] text-black">
								Should you have any questions or need
								assistance, please contact our support team at{' '}
								<a
									className="text-[#9475FF] hover:underline"
									href="mailto:support@carbeeza.com"
								>
									support@carbeeza.com
								</a>
								.
							</p>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default IntroModal;
