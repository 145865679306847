import Modal from '../Modal';

interface ErrorModalProps {
	setModalOpen: (open: boolean) => void;
	response?: string;
}
const ErrorModal = ({ setModalOpen, response }: ErrorModalProps) => {
	return (
		<Modal
			isOpen={true}
			onClose={() => setModalOpen(false)}
			children={
				<div className="flex h-[400px] flex-col	">
					<h2 className="mb-8 text-3xl text-paragraph">
						There was an error
					</h2>
					<p className="text-paragraph">{response}</p>
					<div className="mt-[100px]">
						<div className="ml-auto flex justify-end">
							<button
								type="submit"
								className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
								onClick={() => setModalOpen(false)}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default ErrorModal;
