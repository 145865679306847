import { useEffect, useRef, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import ReactImageFallback from 'react-image-fallback';

import placeHolder from '../../images/placeholder-image.png';
import type {
	TargetVehicleResponse,
	TargetVehicleResponseItem,
} from '../Interfaces';

import { removeHTMLInfo } from '../../utils/string-utils';

interface TargetVehicleProps {
	query: string;
	handleChangeQuery: (e: any) => any;
	searchResults: TargetVehicleResponse;
	similarVehicles: TargetVehicleResponse;
	selectVehicle: (
		v: TargetVehicleResponseItem,
		i: number,
		c: boolean
	) => void;
	setSearchResults: (e: any) => void;
	scenarioVehicle?: TargetVehicleResponseItem;
	country: string;
}

const TargetVehicle = ({
	query,
	handleChangeQuery,
	setSearchResults,
	searchResults,
	selectVehicle,
	similarVehicles,
	scenarioVehicle,
	country,
}: TargetVehicleProps) => {
	const dropDownRef = useRef<any>(null);
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (
				dropDownRef.current &&
				!dropDownRef.current.contains(event.target)
			) {
				setSearchResults(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropDownRef]);
	const [targetVehicle, setTargetVehicle] =
		useState<TargetVehicleResponseItem>({});
	const selectVehicleLocal = (
		option: TargetVehicleResponseItem,
		index: number,
		fromList: boolean
	) => {
		if (fromList) {
			setTargetVehicle(similarVehicles[index]);
		} else {
			setTargetVehicle(searchResults[index]);
		}
		selectVehicle(option, index, fromList);
	};

	useEffect(() => {
		setTargetVehicle({});
	}, [country]);
	useEffect(() => {
		if (scenarioVehicle?.vin) {
			setTargetVehicle(scenarioVehicle);
		} else if (scenarioVehicle?.reset) {
			setTargetVehicle({});
		}
	}, [scenarioVehicle]);
	const similarVehicle = (
		option: TargetVehicleResponseItem,
		index: number
	) => {
		return !option.selected ? (
			<div className="mb-[36px] xs:col-span-2 lg:col-span-1">
				<div className="grid grid-cols-3">
					<div className="xs:col-span-3 md:col-span-1">
						<ReactImageFallback
							src={
								similarVehicles[index]?.vehicleImage ||
								placeHolder
							}
							fallbackImage={placeHolder}
							initialImage={placeHolder}
							className="car-image-background rounded-[8px] object-cover"
						/>
					</div>
					<div className="ml-[20px] text-center xs:col-span-3 md:col-span-2">
						<div
							className="mb-[8px] cursor-pointer text-[20px] text-[#3B2F66] underline"
							onClick={() =>
								selectVehicleLocal(option, index, true)
							}
						>
							{`${
								similarVehicles?.length &&
								similarVehicles[index]?.year
							} ${
								similarVehicles?.length &&
								similarVehicles[index]?.make
							} ${
								similarVehicles?.length &&
								removeHTMLInfo(similarVehicles[index]?.model)
							}`}
						</div>
						<div className="mb-[8px] text-[20px] text-[#3B2F66]">
							<b>Stock #:</b>
							{(similarVehicles?.length &&
								similarVehicles[index]?.inventoryId) ||
								'-'}
						</div>
					</div>
				</div>
			</div>
		) : (
			''
		);
	};
	return (
		<>
			<div className="col-span-2">
				<div className="circular-medium text-xl text-[#3b2f66]">
					2. Target Vehicle
				</div>
			</div>
			<div className="col-span-2">
				<label
					htmlFor="agent-id-number"
					className="mb-[6px] flex text-left text-[14px] font-medium tracking-wider text-[#8A839F]"
				>
					Use the search field below to search for vehicles by Dealer,
					Year, Make, Model or VIN
				</label>
				<input
					type="text"
					className={`w-full rounded bg-white px-5  py-2 outline-none transition disabled:cursor-default
        `}
					value={query}
					onChange={handleChangeQuery}
				/>
				<span className="pointer-events-none absolute">
					<CiSearch className="-ml-7 mt-3 h-5 w-5" />
				</span>
				{searchResults && query !== '' && (
					<div
						ref={dropDownRef}
						className={`dropdown-content absolute z-10 my-2 max-w-[813px] truncate rounded border-2 bg-white p-5 transition duration-300 ease-in-out`}
					>
						{!searchResults.length ? (
							<div className={`block p-2 text-paragraph`}>
								No results found
							</div>
						) : (
							searchResults.map((option, idx) => (
								<div
									key={option.inventoryId}
									className={`block cursor-pointer p-2 text-paragraph hover:bg-sidebar-bk`}
									onClick={() =>
										selectVehicleLocal(option, idx, false)
									}
								>
									{`${option?.year} ${
										option?.make
									} ${removeHTMLInfo(option?.model)}`}
								</div>
							))
						)}
					</div>
				)}
			</div>
			<div className="col-span-2">
				<div className="w-max-800 circular-medium -mb-[12px] -mt-[12px] truncate text-xl  text-[#3b2f66]">
					{targetVehicle?.year
						? `${targetVehicle?.year} ${targetVehicle?.make} ${
								targetVehicle?.model &&
								removeHTMLInfo(targetVehicle?.model)
						  }`
						: 'Vehicle'}
				</div>
			</div>
			<div className="xs:col-span-2 lg:col-span-1">
				<ReactImageFallback
					src={targetVehicle?.vehicleImage || placeHolder}
					fallbackImage={placeHolder}
					initialImage={placeHolder}
					className="car-image-background rounded-[8px] object-cover"
				/>
			</div>
			<div className="mb-[20px] mt-[32px] grid-cols-3 gap-[20px] break-all xs:col-span-2 lg:col-span-1">
				<div className="grid grid-flow-col gap-[20px]">
					<div>
						<span className="block text-[12px] font-bold text-[#111827]">
							Year
						</span>
						<span className="text-[20px] text-[#111827]">
							{targetVehicle?.year || '-'}
						</span>
					</div>
					<div>
						<span className="block text-[12px] font-bold text-[#111827]">
							Make
						</span>
						<span className="text-[20px] text-[#111827]">
							{targetVehicle?.make || '-'}
						</span>
					</div>
					<div className="max-w-[135px] truncate ">
						<span className="block text-[12px] font-bold text-[#111827]">
							Model
						</span>
						<span className=" text-[20px] text-[#111827]">
							{(targetVehicle?.model &&
								removeHTMLInfo(targetVehicle?.model)) ||
								'-'}
						</span>
					</div>
				</div>
				<div className="grid gap-[20px]">
					<div>
						<div>
							<span className="mt-[20px] block text-[12px] font-bold text-[#111827]">
								Trim
							</span>
							<span className="text-[20px] text-[#111827]">
								{targetVehicle?.trim || '-'}
							</span>
						</div>
					</div>

					<div className="grid grid-flow-col gap-[20px]">
						<div>
							<span className="block text-[12px] font-bold text-[#111827]">
								Inventory ID
							</span>
							<span className="text-[20px] text-[#111827]">
								{targetVehicle?.inventoryId || '-'}
							</span>
						</div>
						<div>
							<span className="block text-[12px] font-bold text-[#111827]">
								Mileage
							</span>
							<span className="text-[20px] text-[#111827]">
								{typeof targetVehicle?.mileage === 'number'
									? `${targetVehicle.mileage} ${
											country == 'US' ? 'Mi' : 'KMS'
									  }`
									: '-'}
							</span>
						</div>
					</div>
					<div className="grid grid-flow-col gap-[20px]">
						<div>
							<span className="block text-[12px] font-bold text-[#111827]">
								VIN
							</span>
							<span
								id="analytics-target-vin"
								className="text-[20px] text-[#111827]"
							>
								{targetVehicle?.vin || '-'}
							</span>
						</div>
					</div>
				</div>
			</div>
			{similarVehicles?.length > 1 && (
				// Target vehicle is included in similarVehicles (hence >1)
				<>
					<hr className="col-span-2 mb-[13px] mt-[30px] h-0.5 border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
					<div className="col-span-2">
						{similarVehicles?.length ? (
							<div className="mb-[36px] text-[18px] font-medium text-[#0F172A]">
								Similar vehicles in inventory
							</div>
						) : (
							''
						)}

						<div className="grid grid-cols-2 ">
							{similarVehicles?.length
								? similarVehicles.map((vehicle, idx) => {
										return similarVehicle(vehicle, idx);
								  })
								: ''}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default TargetVehicle;
