export const phoneFormat = (input: any) => {
	if (!input) return input;
	const phoneNumber = input.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;
	if (phoneNumberLength < 4) return `${phoneNumber}`;
	if (phoneNumberLength < 7) {
		return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
	}
	return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
		3,
		6
	)}-${phoneNumber.slice(6)}`;
};

export const validateCAZipCode = (zipCode: string) => {
	const validate =
		/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][\s?]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
			zipCode
		);
	return !validate;
};

export const validateUSZipCode = (zipCode: string) => {
	const validate = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
	return !validate;
};

export const validateId = (id: string) => {
	const validate = /(^[0-9]{0,}$)/.test(id);
	return !validate;
};

export const validateEmail = (email: string) => {
	if (email == '') {
		return true;
	}
	const validate = String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	return validate;
};

export const applyMaxLength = (value: any, length: number) => {
	if (value.length > length) {
		return value.slice(0, length);
	}
	return value;
};

export const CAZipFormat = (input: any) => {
	if (!input) return input;
	const zip = input.replace(/[^0-9a-zA-Z]/g, '');
	const zipLength = zip.length;
	if (zipLength < 4) return zip;
	return `${zip.slice(0, 3)} ${zip.slice(3, 6)}`;
};

export const validateCreditScoreField = (score: string) => {
	let formatted_score = parseInt(score.replace(/[^0-9]+/gi, ''));

	const MAX_SCORE = 900;
	if (formatted_score > MAX_SCORE) {
		formatted_score = MAX_SCORE;
	}
	return formatted_score;
};
