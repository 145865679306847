import { useEffect, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tooltip';

import { CanadaStates, UnitedStates } from '../../api/constants';
import {
	calculate,
	getScenarios,
	searchTarget,
	validateTrialToken,
} from '../../api/services';
import ErrorModal from '../../components/ErrorModal';
import FeedbackButton from '../../components/FeedbackButton';
import IntroModal from '../../components/IntroModal';
import MoneyInput from '../../components/MoneyInput';
import SuccessModal from '../../components/SuccessModal';
import TextInput from '../../components/TextInput';
import Loader from '../../images/loader-gif.gif';
import OnboardingLayout from '../../layout/OnboardingLayout';
import {
	CAZipFormat,
	validateCreditScoreField,
	applyMaxLength,
	phoneFormat,
	validateUSZipCode,
	validateCAZipCode,
	validateEmail,
} from '../../utils/form-utils';
import {
	type DealResponse,
	type CalculateDealPayload,
	type CustomerInformation,
	type Scenario,
	type TargetVehicleResponse,
	type TargetVehicleResponseItem,
	type ValidateTrialRequest,
	type ValidateTrialResponse,
} from '../Interfaces';

import DealHUD from './DealHUD';
import DealModal from './DealModal';
import TargetVehicle from './TargetVehicle';
import TradeIn from './TradeIn';
import TradeInModal from './TradeInModal';

const trialFieldsToDisable: Array<string> = [
	'firstName',
	'lastName',
	'income',
	'downPayment',
	'reportedCredit',
	'creditProvider',
];

const TestingTool = () => {
	const { trialToken } = useParams();

	const [isActiveTrial, setIsActiveTrial] = useState(false);
	const [isTrialRejected, setIsTrialRejected] = useState(false);
	const [trialExpiryDate, setTrialExpiryDate] = useState(new Date());
	const [disabledFields, setDisabledFields] = useState(trialFieldsToDisable);

	const updateTrialValidation = async (token: string = '') => {
		// console.log(token);
		if (token) {
			try {
				const response = await validateTrialToken({
					token: token,
				});
				if (
					isActiveTrial !==
					response?.data?.validateTrialByTokenResponse?.trial_active
				) {
					setShowIntroModal(
						response?.data?.validateTrialByTokenResponse
							?.trial_active === true
					);
				}
				if (
					response?.data?.validateTrialByTokenResponse?.admin === true
				) {
					setDisabledFields([]);
				} else {
					setDisabledFields(trialFieldsToDisable);
				}

				// console.log(res);
				setIsActiveTrial(
					response?.data?.validateTrialByTokenResponse
						?.trial_active === true
				);
				setIsTrialRejected(
					response?.data?.validateTrialByTokenResponse
						?.trial_active !== true
				);
				setTrialExpiryDate(
					response?.data?.validateTrialByTokenResponse?.expiry_date
						? new Date(
								response?.data?.validateTrialByTokenResponse?.expiry_date
						  )
						: new Date()
				);
			} catch (error) {
				setIsActiveTrial(false);
				setIsTrialRejected(true);
				setTrialExpiryDate(new Date());
				setDisabledFields(trialFieldsToDisable);
				console.log(error);
			}
		}
	};

	useEffect(() => {
		updateTrialValidation(trialToken);
	}, [trialToken]);

	const [isLoading, setIsLoading] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showIntroModal, setShowIntroModal] = useState(false);
	const [showTradeInModal, setShowTradeInModal] = useState(false);
	const [errorResponse, setErrorResponse] = useState('');
	const [customer, setCustomer] = useState<CustomerInformation>({
		country: 'US',
		zip: '',
		state: '',
		creditProvider: '',
	});
	const [inputAction, setInputAction] = useState(false);
	const [query, setQuery] = useState('');
	const [searchResults, setSearchResults] = useState<TargetVehicleResponse>(
		[]
	);
	const [similarVehicles, setSimilarVehicles] =
		useState<TargetVehicleResponse>([]);
	const [targetVehicle, setTargetVehicle] =
		useState<TargetVehicleResponseItem>({});
	const [scenarios, setScenarios] = useState([]);
	const [showDealModal, setShowDealModal] = useState(false);
	const [deal, setDeal] = useState<DealResponse>({});
	const [scenarioVehicle, setScenarioVehicle] =
		useState<TargetVehicleResponseItem>({});
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [errorCodes, setErrorCodes] = useState(['']);
	const firstUpdate = useRef(false);
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
		}
	}, []);

	useEffect(() => {
		if (!firstUpdate.current) {
			firstUpdate.current = true;
			return;
		}

		const timeoutId = setTimeout(() => {
			calculateDeal();
		}, 3000);
		return () => clearTimeout(timeoutId);
	}, [inputAction]);

	useEffect(() => {
		getScenarios().then((res) => {
			setScenarios(res?.data?.scenarios);
		});
	}, []);
	// Reset timer on every change
	const calculateDealDebounced = () => {
		setIsLoading(true);
		setInputAction(!inputAction);
	};

	const handleChangeQuery = (e: any) => {
		setQuery(e.target.value);
	};

	useEffect(() => {
		if (query !== '') {
			const timer = setTimeout(() => {
				const searchParams = {
					country: customer.country || 'US',
					query: query,
				};
				searchTarget(searchParams)
					.then((res) => {
						setSearchResults(res.data.response);
					})
					.catch((e: Error) => {
						console.log(e);
					});
			}, 500);
			return () => clearTimeout(timer);
		}
	}, [query]);

	const ValidateFields = () => {
		//Add validation if required
		toggleDealModal(true);
	};
	const calculateDeal = () => {
		setShowErrorMessage(false);
		// if (!customer.zip) {
		//   return
		// }
		const MIN_SCORE = 300;
		if (customer?.reportedCredit) {
			if (customer?.reportedCredit < MIN_SCORE) {
				setCustomer((prevState) => ({
					...prevState,
					reportedCredit: MIN_SCORE,
				}));
			}
		}
		const data: CalculateDealPayload = {
			Country: customer?.country,
			CreditProvider: customer?.creditProvider,
			CreditScore: !customer?.reportedCredit
				? undefined
				: customer?.reportedCredit &&
				  customer?.reportedCredit > MIN_SCORE
				? customer?.reportedCredit
				: MIN_SCORE,
			DownPayment: customer?.downPayment,
			Income: customer?.income,
			ListPrice: targetVehicle?.price,
			MaxMonthly: customer?.maxMonthly,
			PostCode: customer?.zip,
			VehicleListingType:
				targetVehicle?.mileage && targetVehicle?.mileage > 0
					? 'Used'
					: 'New',
			VehicleMake: targetVehicle?.make,
			VehicleMileage: targetVehicle?.mileage,
			VehicleModel: targetVehicle?.model,
			VehicleTrim: targetVehicle?.trim,
			VehicleVin: targetVehicle?.vin,
			VehicleYear: targetVehicle?.year,
			// Term:
			//
		}; //Add missing props
		setErrorCodes(['']);
		calculate(data)
			.then((res) => {
				setIsLoading(false);
				setDeal(res.data.data);
				if (res.data.errors.length) {
					setShowErrorMessage(true);
					setErrorCodes(res.data.errors);
				}
				//setShowSuccessModal(true)
				//show confirmation modal

				// update trial validation
				updateTrialValidation(trialToken);
			})
			.catch((data) => {
				setIsLoading(false);
				setShowErrorMessage(true);
				setDeal({});
				//setShowErrorModal(true)
				setErrorResponse(data?.response);
				//show error modal
			});
	};
	const handleChangeInput = (e: any) => {
		const fieldName = e.target.name;
		if (fieldName === 'country') {
			resetAddresses();
		}
		let fieldValue =
			fieldName === 'vin'
				? e.target.value.replace(/[^0-9a-zA-Z]+/gi, '')
				: fieldName === 'reportedCredit'
				? validateCreditScoreField(e.target.value)
				: fieldName === 'zip' && customer.country === 'CA'
				? CAZipFormat(e.target.value)
				: fieldName === 'zip' && customer.country === 'US'
				? applyMaxLength(e.target.value, 5)
				: fieldName === 'phone'
				? phoneFormat(e.target.value)
				: // : e.target.type === 'number'
				  // ? applyMaxLength(e.target.value)
				  e.target.value;
		setCustomer((prevState) => ({
			...prevState,
			[fieldName]: fieldValue,
		}));
		calculateDealDebounced();
	};

	const selectVehicle = (
		vehicle: TargetVehicleResponseItem,
		idx: number,
		changeVehicle: boolean
	) => {
		setTargetVehicle(vehicle);

		const results = changeVehicle ? similarVehicles : searchResults;
		for (let index = 0; index < results.length; index++) {
			if (index === idx) {
				results[index].selected = true;
			} else {
				results[index].selected = false;
			}
		}

		setSimilarVehicles(results);
		setSearchResults([]);
		calculateDealDebounced();
		setQuery('');
	};

	const handleChangeOption = (option: Scenario) => {
		//Set form data
		setCustomer({
			address: option?.address || '',
			city: option?.city || '',
			comments: option?.comments || '',
			country: option?.country || '',
			creditProvider: option?.creditProvider || '',
			downPayment: option?.downPayment || 0,
			email: option?.email || '',
			firstName: option?.firstName || '',
			income: option?.income || 0,
			lastName: option?.lastName || '',
			maxMonthly: option?.maxMonthly || 0,
			phone: option?.phone || '',
			reportedCredit: option?.reportedCredit || 0,
			state: option?.state || '',
			zip: option?.zip || '',
		});

		setTargetVehicle(option?.targetVehicle || {});
		setSimilarVehicles([]);
		setScenarioVehicle(option?.targetVehicle || {});
		calculateDealDebounced();
		setQuery('');
	};

	const toggleDealModal = (open: boolean) => {
		setShowDealModal(open);
	};

	const resetAddresses = () => {
		setSimilarVehicles([]);
		setTargetVehicle({});
		setCustomer((prevState) => ({
			...prevState,
			city: '',
			state: '',
			zip: '',
		}));
	};

	const resetForm = () => {
		//Set form data
		setCustomer({
			address: '',
			city: '',
			comments: '',
			country: 'US',
			creditProvider: '',
			downPayment: undefined,
			email: '',
			firstName: '',
			income: undefined,
			lastName: '',
			maxMonthly: undefined,
			phone: '',
			reportedCredit: undefined,
			state: '',
			zip: '',
		});

		setQuery('');
		setSearchResults([]);
		setIsLoading(false);
		setShowErrorMessage(false);
		setDeal({});
		setShowErrorModal(false);
		setErrorResponse('');
		setErrorCodes(['']);
		setIsLoading(false);

		setSimilarVehicles([]);
		setTargetVehicle({});
		setScenarioVehicle({ reset: true });
	};

	return (
		<OnboardingLayout
			options={scenarios}
			onChangeOption={handleChangeOption}
			resetForm={resetForm}
			trialToken={trialToken || ''}
			isActiveTrial={isActiveTrial}
			trialExpiryDate={trialExpiryDate}
		>
			<div className="grid h-full max-w-[1360px] gap-[40px] xs:w-11/12 xs:grid-cols-3">
				<div className="xs:col-span-3 lg:col-span-3">
					<h1 className="text-3xl">Drive insight with UltraLead</h1>
					<br />
					<div
						hidden={!isTrialRejected}
						className="error-alert px-[40px] py-[40px]"
					>
						<div className="text-[#E70033]">
							<b className="text-[20px] font-bold">
								Trial Expired
							</b>
							<br />{' '}
							<p className="text-[20px]">
								Your free trial has expired. Please contact our
								support team at{' '}
								<a href="mailto:support@carbeeza.com">
									support@carbeeza.com
								</a>{' '}
								to sign-up or request another trial.
							</p>
						</div>
					</div>
					<div hidden={isTrialRejected || isActiveTrial}>
						<img src={Loader} alt="loading" className="m-auto" />
					</div>
				</div>
				<div
					hidden={!isActiveTrial}
					className="card bg-[#F9F9F9] px-[40px] py-[40px] xs:col-span-3 lg:col-span-2"
				>
					<div className="mb-[27px] grid grid-cols-2 gap-x-[35px] gap-y-[20px]">
						<div className="col-span-2 mb-[20px]">
							<div className="circular-medium text-xl text-[#3b2f66]">
								1. Customer Information
							</div>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							{/* <a
                                data-tooltip-id={`${'firstName'}-anchor-element`}
                            > */}
							<TextInput
								inputId={'firstName'}
								label={'First Name'}
								value={customer.firstName || ''}
								onChangeFunction={handleChangeInput}
								placeholder="First Name"
								disabled={
									disabledFields.indexOf('firstName') >= 0
								}
							/>
							{/* </a>
                            <Tooltip
                                id={`${'firstName'}-anchor-element`}
                                className="lender-tooltip"
                                place="top"
                                style={{
                                    background: 'white',
                                    borderColor: '#EFEAFF',
                                    borderWidth: '3px',
                                    color: '#464B4F',
                                    fontSize: '16px',
                                }}
                            >
                                Name fields are not included in the trial at
                                this time.
                            </Tooltip> */}
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							{/* <a data-tooltip-id={`${'lastName'}-anchor-element`}> */}
							<TextInput
								inputId={'lastName'}
								label={'Last Name'}
								value={customer.lastName || ''}
								onChangeFunction={handleChangeInput}
								placeholder="Last Name"
								disabled={
									disabledFields.indexOf('lastName') >= 0
								}
							/>
							{/* </a>
                            <Tooltip
                                id={`${'lastName'}-anchor-element`}
                                className="lender-tooltip"
                                place="top"
                                style={{
                                    background: 'white',
                                    borderColor: '#EFEAFF',
                                    borderWidth: '3px',
                                    color: '#464B4F',
                                    fontSize: '16px',
                                }}
                            >
                                Name fields are not included in the trial at
                                this time.
                            </Tooltip> */}
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<TextInput
								inputId={'address'}
								label={'Address Line 1'}
								value={customer.address || ''}
								onChangeFunction={handleChangeInput}
								placeholder="Street Address"
								disabled={
									disabledFields.indexOf('address') >= 0
								}
							/>
						</div>
						<div className="xs:col-span-2 lg:col-span-1"></div>
						<div className="xs:col-span-2 lg:col-span-1">
							<TextInput
								inputId={'city'}
								label={'City'}
								value={customer.city || ''}
								onChangeFunction={handleChangeInput}
								placeholder="City Name"
								disabled={disabledFields.indexOf('city') >= 0}
							/>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<label
								htmlFor="customer-state"
								className="mb-[6px] flex text-left text-[12px] font-medium tracking-wider	"
							>
								State/Province
							</label>
							<select
								id="state"
								name="state"
								className={`${
									!customer?.state || customer?.state == ''
										? 'text-[#909090]'
										: ''
								}
                dropdown border-1 w-full rounded border-[#8874C3] px-5 py-2  outline-none transition disabled:cursor-default
				${disabledFields.indexOf('state') >= 0 ? 'bg-gray' : 'bg-white'}`}
								value={customer.state}
								onChange={handleChangeInput}
								placeholder="Select State/Province"
								// defaultValue={''}
								disabled={disabledFields.indexOf('state') >= 0}
							>
								<option
									disabled
									value=""
									className="text-[#909090]"
								>
									Select State/Province
								</option>
								{customer.country === 'US'
									? UnitedStates.map((state) => {
											return (
												<option
													key={state.code}
													value={state.code}
													className="text-[#0F172]"
												>
													{state.state}
												</option>
											);
									  })
									: customer.country === 'CA'
									? CanadaStates.map((state) => {
											return (
												<option
													value={state.code}
													className="text-[#0F172]"
												>
													{state.state}
												</option>
											);
									  })
									: null}
								{/** Add provinces */}
							</select>
							<span className="pointer-events-none absolute">
								<FaChevronDown className="-ml-7 mt-4 h-3 w-3" />
							</span>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<label
								htmlFor="customer-country"
								className="mb-[6px] flex text-left text-[12px] font-medium tracking-wider	"
							>
								Country
							</label>
							<select
								id="countries"
								name="country"
								value={customer.country}
								onChange={handleChangeInput}
								// defaultValue={''}
								disabled={
									disabledFields.indexOf('countries') >= 0
								}
								className={`
                ${
					!customer?.country || customer?.country == ''
						? 'text-[#909090]'
						: ''
				}
                  w-full rounded px-5 py-2 outline-none transition disabled:cursor-default
				  ${disabledFields.indexOf('countries') >= 0 ? 'bg-gray' : 'bg-white'}`}
							>
								<option disabled value={''}>
									Select a country
								</option>
								<option value="US">United States</option>
								<option value="CA">Canada</option>
							</select>
							<span className="pointer-events-none absolute">
								<FaChevronDown className="-ml-7 mt-4 h-3 w-3" />
							</span>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<TextInput
								inputId={'zip'}
								label={'ZIP/Postal Code'}
								value={customer.zip || ''}
								isNumeric={customer?.country == 'US'}
								onChangeFunction={handleChangeInput}
								placeholder={
									customer?.country == 'US'
										? '12345'
										: customer?.country == 'CA'
										? 'T5X 1X3'
										: 'Zip/Postal Code'
								}
								isError={
									customer?.country == 'US' &&
									customer?.zip !== ''
										? validateUSZipCode(customer?.zip || '')
										: customer?.country == 'CA' &&
										  customer?.zip != ''
										? validateCAZipCode(customer?.zip || '')
										: false
								}
								disabled={disabledFields.indexOf('zip') >= 0}
							/>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<TextInput
								inputId={'phone'}
								label={'Phone'}
								value={customer?.phone || ''}
								onChangeFunction={handleChangeInput}
								placeholder="(123)-456-7890"
								disabled={disabledFields.indexOf('phone') >= 0}
							/>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<TextInput
								inputId={'email'}
								label={'Email'}
								value={customer?.email || ''}
								onChangeFunction={handleChangeInput}
								placeholder="email@email.com"
								isError={
									!customer?.email
										? false
										: validateEmail(customer?.email) ===
										  null
										? true
										: false
								}
								disabled={disabledFields.indexOf('email') >= 0}
							/>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<a data-tooltip-id={`${'income'}-anchor-element`}>
								<MoneyInput
									inputId={'income'}
									label={'Income'}
									value={customer?.income?.toString() || ''}
									onChangeFunction={handleChangeInput}
									country={customer?.country || 'US'}
									disabled={
										disabledFields.indexOf('income') >= 0
									}
								/>
							</a>
							<Tooltip
								id={`${'income'}-anchor-element`}
								className="lender-tooltip"
								place="top"
								style={{
									background: 'white',
									borderColor: '#EFEAFF',
									borderWidth: '3px',
									color: '#464B4F',
									fontSize: '16px',
								}}
							>
								UltraLead considers the consumer's total annual
								<br />
								income.
							</Tooltip>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<MoneyInput
								inputId={'maxMonthly'}
								label={'Max Monthly Payment'}
								value={customer?.maxMonthly?.toString() || ''}
								onChangeFunction={handleChangeInput}
								country={customer?.country || 'US'}
								disabled={
									disabledFields.indexOf('maxMonthly') >= 0
								}
							/>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<a
								data-tooltip-id={`${'downPayment'}-anchor-element`}
							>
								<MoneyInput
									inputId={'downPayment'}
									label={'Desired Down Payment'}
									value={
										customer?.downPayment?.toString() || ''
									}
									onChangeFunction={handleChangeInput}
									country={customer?.country || 'US'}
									disabled={
										disabledFields.indexOf('downPayment') >=
										0
									}
								/>
							</a>
							<Tooltip
								id={`${'downPayment'}-anchor-element`}
								className="lender-tooltip"
								place="top"
								style={{
									background: 'white',
									borderColor: '#EFEAFF',
									borderWidth: '3px',
									color: '#464B4F',
									fontSize: '16px',
								}}
							>
								The down payment amount is factored into
								<br />
								financeability and lender selection.
							</Tooltip>
						</div>
						<div className="xs:col-span-2 lg:col-span-1"></div>
						<div className="xs:col-span-2 lg:col-span-1">
							<a
								data-tooltip-id={`${'reportedCredit'}-anchor-element`}
							>
								<TextInput
									inputId={'reportedCredit'}
									label={'Reported Credit'}
									value={
										customer?.reportedCredit?.toString() ||
										''
									}
									onChangeFunction={handleChangeInput}
									placeholder="Enter customer's credit score"
									isNumeric={true}
									isError={
										!customer?.reportedCredit
											? false
											: customer?.reportedCredit > 900
											? true
											: customer?.reportedCredit < 300
											? true
											: false
									}
									disabled={
										disabledFields.indexOf(
											'reportedCredit'
										) >= 0
									}
								/>
							</a>
							<Tooltip
								id={`${'reportedCredit'}-anchor-element`}
								className="lender-tooltip"
								place="top"
								style={{
									background: 'white',
									borderColor: '#EFEAFF',
									borderWidth: '3px',
									color: '#464B4F',
									fontSize: '16px',
								}}
							>
								Where provided, reported credit score is taken
								<br />
								into account by UltraLead.
							</Tooltip>
						</div>
						<div className="xs:col-span-2 lg:col-span-1">
							<a
								data-tooltip-id={`${'creditProvider'}-anchor-element`}
							>
								<label
									htmlFor="customer-country"
									className="mb-[6px] flex text-left text-[12px] font-medium tracking-wider	"
								>
									Credit Provider
								</label>
								<select
									id="creditProvider"
									name="creditProvider"
									value={customer.creditProvider}
									onChange={handleChangeInput}
									// defaultValue={''}
									disabled={
										disabledFields.indexOf(
											'creditProvider'
										) >= 0
									}
									className={`
                  ${
						!customer?.creditProvider ||
						customer?.creditProvider == ''
							? 'text-[#909090]'
							: ''
					}
                  border-1 w-full rounded border-[#8874C3] px-5 py-2 outline-none transition disabled:cursor-default
				  ${disabledFields.indexOf('creditProvider') >= 0 ? 'bg-gray' : 'bg-white'}`}
								>
									<option disabled value="">
										Select a credit score provider
									</option>
									<option value="Trans Union Soft">
										Trans Union Soft
									</option>
									{customer?.country === 'US' && (
										<option value="Experian Soft">
											Experian Soft
										</option>
									)}
									<option value="Self Reported">
										Self Reported
									</option>
									<option value="Equifax">Equifax</option>
								</select>
								<span className="pointer-events-none absolute">
									<FaChevronDown className="-ml-7 mt-4 h-3 w-3" />
								</span>
							</a>
							<Tooltip
								id={`${'creditProvider'}-anchor-element`}
								className="lender-tooltip"
								place="top"
								style={{
									background: 'white',
									borderColor: '#EFEAFF',
									borderWidth: '3px',
									color: '#464B4F',
									fontSize: '16px',
								}}
							>
								UltraLead weights credit reported scores based
								<br />
								on their source.
							</Tooltip>
						</div>

						<div className="col-span-2">
							<label
								htmlFor="customer-country"
								className="mb-[6px] flex text-left text-[12px] font-medium tracking-wider	"
							>
								Comments
							</label>
							<textarea
								id="notes"
								name="comments"
								rows={1}
								onChange={handleChangeInput}
								value={customer.comments}
								placeholder="Please enter notes here"
								disabled={disabledFields.indexOf('notes') >= 0}
								className={
									'border-1 w-full rounded border-[#8874C3] bg-white px-5  py-2 outline-none transition disabled:cursor-default'
								}
							/>
						</div>
						<div className="col-span-2">
							<hr className="mb-[50px] mt-[0px] h-0.5 border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
						</div>
						<TargetVehicle
							query={query}
							handleChangeQuery={handleChangeQuery}
							searchResults={searchResults}
							setSearchResults={setSearchResults}
							selectVehicle={selectVehicle}
							scenarioVehicle={scenarioVehicle}
							similarVehicles={similarVehicles}
							country={customer.country || 'US'}
						/>
						{/* <hr className="col-span-2 mb-[40px] mt-[30px] h-0.5 border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
            <TradeIn /> */}
						{/********************************************************************Button */}

						<div className="col-span-2 w-full">
							<hr />
						</div>
						<div className="col-span-2 mt-[30px]">
							<FeedbackButton />
						</div>
					</div>
				</div>
				<div hidden={!isActiveTrial}>
					<DealHUD
						targetVehicle={targetVehicle}
						toggleDealModal={ValidateFields}
						deal={deal}
						isLoading={isLoading}
						showErrorMessage={showErrorMessage}
						errorCodes={errorCodes}
					/>
				</div>
			</div>
			{showSuccessModal && (
				<SuccessModal setModalOpen={setShowSuccessModal} />
			)}
			{showErrorModal && (
				<ErrorModal
					setModalOpen={setShowErrorModal}
					response={errorResponse}
				/>
			)}
			{showIntroModal && <IntroModal setModalOpen={setShowIntroModal} />}
			{showTradeInModal && <TradeInModal />}
			{showDealModal && (
				<DealModal
					targetVehicle={targetVehicle}
					customer={customer}
					setModalOpen={toggleDealModal}
					deal={deal}
					errorCodes={errorCodes}
				/>
			)}
		</OnboardingLayout>
	);
};

export default TestingTool;
