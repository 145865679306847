import ReactImageFallback from 'react-image-fallback';

import FeedbackButton from '../../components/FeedbackButton';
import Modal from '../../components/Modal';
import placeHolder from '../../images/placeholder-image.png';
import { USDollar } from '../../utils/money-utils';
import type {
	CustomerInformation,
	DealResponse,
	TargetVehicleResponseItem,
} from '../Interfaces';

import DealModalGridItem from './DealModalGridItem';
import LendersTable from './LendersTable';

import { removeHTMLInfo } from '../../utils/string-utils';

interface DealModalProps {
	setModalOpen: (open: boolean) => void;
	response?: string;
	targetVehicle?: TargetVehicleResponseItem;
	customer?: CustomerInformation;
	deal?: DealResponse;
	errorCodes?: string[];
}
const DealModal = ({
	setModalOpen,
	response,
	targetVehicle,
	customer,
	deal,
	errorCodes,
}: DealModalProps) => {
	const scoreCeil = Math.round(
		deal?.estimated_credit
			? deal?.estimated_credit + deal?.estimated_credit / 200
			: 900
	);
	const scoreColor =
		scoreCeil < 580
			? 'text-[#E70033]'
			: scoreCeil > 580 && scoreCeil < 670
			? 'text-[#FF7643]'
			: scoreCeil > 670 && scoreCeil < 740
			? 'text-[#EDB700]'
			: scoreCeil > 740 && scoreCeil < 800
			? 'text-[#7ED957]'
			: scoreCeil > 799
			? 'text-[#008037]'
			: 'text-[#909090]';

	const formatCurrency = function (value: number | undefined): string {
		if (value !== undefined) {
			const amount = USDollar.format(value);
			const suffix = customer?.country ? customer.country + 'D' : '';
			return `${amount} ${suffix}`;
		} else {
			return 'N/A';
		}
	};

	return (
		<Modal
			isOpen={true}
			onClose={() => setModalOpen(false)}
			title={
				<div className="max-w-[950px] truncate">
					<span className="text-[32px] font-bold text-[#3B2F66]">
						Target Vehicle:{' '}
					</span>
					<span className="ml-[39px] text-[32px] font-normal text-[#3B2F66]">
						{targetVehicle?.year} {targetVehicle?.make}{' '}
						{targetVehicle?.model &&
							removeHTMLInfo(targetVehicle?.model)}
					</span>
				</div>
			}
		>
			<>
				<div
					id="analytics-deal-modal"
					className="grid-col grid grid-cols-2 gap-x-[70px] gap-y-[39px]"
				>
					<div className="col-span-2">
						<hr className="h-0.5 w-full border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
					</div>
					<div className="relative col-span-2 lg:col-span-1">
						<div className="mb-[40px] flex flex-col">
							<p className="tracking-[0.294px]' text-[28px] font-[700] leading-[44px] text-[#725DB9]">
								Customer Information
							</p>
							<div className="mt-[61px] grid grid-cols-3 gap-x-[5px] gap-y-[40px]">
								<DealModalGridItem
									fieldTitle="Name"
									fieldValue={
										customer?.firstName ||
										customer?.lastName
											? `${customer?.firstName} ${customer?.lastName}`
											: 'Not Provided'
									}
									containerClasses="col-span-3"
								/>
								<DealModalGridItem
									fieldTitle="Phone"
									fieldValue={
										customer?.phone || 'Not Provided'
									}
								/>
								<DealModalGridItem
									fieldTitle="Email"
									fieldValue={
										customer?.email || 'Not Provided'
									}
									containerClasses="col-span-2"
								/>
								<DealModalGridItem
									fieldTitle="Street Address"
									fieldValue={
										customer?.address || 'Not Provided'
									}
								/>
								<DealModalGridItem
									fieldTitle="City"
									fieldValue={
										customer?.city || 'Not Provided'
									}
								/>
								<DealModalGridItem
									fieldTitle="State/Province"
									fieldValue={
										customer?.state || 'Not Provided'
									}
								/>
								<DealModalGridItem
									fieldTitle="Zip/Postal Code"
									fieldValue={customer?.zip || 'Not Provided'}
								/>
							</div>
							<hr className="absolute bottom-0 h-0.5 w-full border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
						</div>
					</div>
					{
						//Vehicle Information
					}
					<div className="relative col-span-2 lg:col-span-1">
						<div className="mb-[40px] flex flex-col">
							<p className="tracking-[0.294px]' text-[28px] font-[700] leading-[44px] text-[#725DB9]">
								Vehicle Information
							</p>
							<div className="mt-[24px]">
								<ReactImageFallback
									src={targetVehicle?.vehicleImage}
									fallbackImage={placeHolder}
									initialImage={placeHolder}
									alt="deal-vehicle"
									className="car-image-background m-auto h-[320px] w-[520px] object-cover"
								/>
							</div>
							<div className="mt-[24px] grid grid-cols-4 gap-x-[5px] gap-y-[40px]">
								<DealModalGridItem
									fieldTitle="Year"
									fieldValue={
										targetVehicle?.year
											? String(targetVehicle?.year)
											: 'Not Provided'
									}
								/>
								<DealModalGridItem
									fieldTitle="Make"
									fieldValue={
										targetVehicle?.make || 'Not Provided'
									}
								/>
								<DealModalGridItem
									fieldTitle="Model"
									fieldValue={
										targetVehicle?.model
											? removeHTMLInfo(
													targetVehicle?.model
											  )
											: 'Not Provided'
									}
								/>
								<DealModalGridItem
									fieldTitle="Stock"
									fieldValue={
										targetVehicle?.inventoryId ||
										targetVehicle?.make ||
										'Not Provided'
									}
								/>
								<DealModalGridItem
									fieldTitle="VIN"
									fieldValue={
										targetVehicle?.vin || 'Not Provided'
									}
									containerClasses="col-span-4"
								/>
								<DealModalGridItem
									fieldTitle="Trim"
									fieldValue={
										targetVehicle?.trim || 'Not Provided'
									}
									containerClasses="col-span-4"
								/>
							</div>
						</div>
						<hr className="absolute bottom-0 h-0.5 w-full border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
					</div>
					<p className="tracking-[0.294px]' col-span-2 text-[28px] font-[700] leading-[44px] text-[#725DB9]">
						Financing Information
					</p>
					<div className="col-span-2 lg:col-span-1">
						<div className="flex flex-col ">
							<div className="mt-[-16px] grid grid-cols-3 gap-x-[5px] gap-y-[40px]">
								<DealModalGridItem
									fieldTitle="Advertised Price"
									fieldValue={formatCurrency(
										deal?.listing_price
									)}
									containerClasses="col-span-2"
								/>
								<DealModalGridItem
									fieldTitle="Finance Type"
									fieldValue="Finance"
								/>
								<DealModalGridItem
									fieldTitle="Reported Income"
									fieldValue={formatCurrency(
										customer?.income
									)}
								/>
								<DealModalGridItem
									fieldTitle="Down Payment"
									fieldValue={formatCurrency(
										deal?.calculated_downpayment
									)}
								/>
								<DealModalGridItem
									fieldTitle="Max Monthly Payment"
									fieldValue={formatCurrency(
										customer?.maxMonthly
									)}
								/>
								<DealModalGridItem
									fieldTitle="Amount Borrowed"
									fieldValue={formatCurrency(
										deal?.amount_borrowed
									)}
								/>
							</div>
						</div>
					</div>
					<div className="col-span-2 lg:col-span-1">
						<div className="flex flex-col ">
							<div className="grid grid-cols-3 gap-x-[5px] gap-y-[40px] lg:mt-[-16px]">
								<DealModalGridItem
									fieldTitle="Reported Credit"
									fieldValue={
										customer?.reportedCredit
											? String(customer?.reportedCredit)
											: 'N/A'
									}
								/>
								<DealModalGridItem
									fieldTitle="Estimated Credit Range"
									fieldValue={
										deal?.estimated_credit
											? `${Math.round(
													deal.estimated_credit -
														deal?.estimated_credit /
															200
											  )}-${Math.round(
													deal.estimated_credit +
														deal?.estimated_credit /
															200
											  )}`
											: 'N/A'
									}
								/>
								<DealModalGridItem
									fieldTitle="Credit Tier Type"
									fieldValue={deal?.credit_flavour || 'N/A'}
									valueTailwindClassOptions={{
										color: scoreColor,
									}}
								/>
								<DealModalGridItem
									fieldTitle="Confidence Score"
									fieldValue={
										deal?.confidence?.score
											? String(deal?.confidence?.score)
											: 'N/A'
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="sm:col-span-2 md:col-span-1">
					<hr className="mb-[39px] mt-[39px] h-0.5 w-full border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
					<FeedbackButton label={'Rate Results'} />
					<div className="flex flex-col ">
						<p className="tracking-[0.294px]' mb-6 text-[28px] font-[700] leading-[44px] text-[#725DB9]">
							Lenders
						</p>
						<LendersTable
							data={deal?.programs}
							country={customer?.country}
							maxMonthly={customer?.maxMonthly}
							deal={deal}
							errorCodes={errorCodes}
						/>
					</div>
				</div>
				<div className="disclaimer">
					* Estimated payment exceeds customers requested monthly
					payment amount
				</div>
			</>
		</Modal>
	);
};

export default DealModal;
