// import GaugeComponent from 'react-gauge-component';
import { lazy, Suspense } from 'react';
import ReactImageFallback from 'react-image-fallback';

import placeHolder from '../../images/placeholder-image.png';
import { USDollar } from '../../utils/money-utils';
import { removeHTMLInfo } from '../../utils/string-utils';
import type { DealResponse, TargetVehicleResponseItem } from '../Interfaces';

import LoadingOverlay from './LoadingOverlay';

// Dynamic import of GaugeComponent to handle 'require'
const GaugeComponent = lazy(() => import('react-gauge-component'));

interface DealHUDProps {
	targetVehicle: TargetVehicleResponseItem;
	deal: DealResponse;
	toggleDealModal: (open: boolean) => void;
	isLoading: boolean;
	showErrorMessage: boolean;
	errorCodes?: string[];
}

export const ERRORS = {
	AMOUNT_TOO_SMALL: 'amount_too_small',
	NON_FINANCIBLE: 'non_financible',
};

const DealHUD = ({
	targetVehicle,
	toggleDealModal,
	deal,
	isLoading,
	showErrorMessage,
	errorCodes,
}: DealHUDProps) => {
	const scoreCeil = Math.round(
		deal?.estimated_credit
			? deal?.estimated_credit + deal?.estimated_credit / 200
			: 0
	);
	const scoreColor = !deal?.estimated_credit
		? 'text-[#909090]'
		: scoreCeil < 580
		? 'text-[#E70033]'
		: scoreCeil > 580 && scoreCeil < 670
		? 'text-[#FF7643]'
		: scoreCeil > 670 && scoreCeil < 740
		? 'text-[#EDB700]'
		: scoreCeil > 740 && scoreCeil < 800
		? 'text-[#7ED957]'
		: scoreCeil > 799
		? 'text-[#008037]'
		: 'text-[#909090]';

	const renderGauge = () => {
		return (
			<Suspense>
				<GaugeComponent
					value={
						!deal?.estimated_credit
							? 0
							: scoreCeil < 580
							? 10 //Poor 300 - 579
							: scoreCeil < 670
							? 30 //Fair 580 - 669
							: scoreCeil < 740
							? 50 //GOod 670 -
							: scoreCeil < 800
							? 70 // Very Good
							: 90 //Excellent
					}
					type="semicircle"
					labels={{
						tickLabels: {
							hideMinMax: true,
							ticks: [],
							type: 'inner',
						},
						valueLabel: {
							style: {
								display: 'none',
							},
						},
					}}
					arc={{
						colorArray: [
							!deal?.estimated_credit
								? '#d6d6d6'
								: scoreCeil < 580
								? '#E70033'
								: '#D7D3E9',
							!deal?.estimated_credit
								? '#afafaf'
								: scoreCeil > 580 && scoreCeil < 670
								? '#FF7643'
								: '#B1ACC2',
							!deal?.estimated_credit
								? '#868686'
								: scoreCeil > 670 && scoreCeil < 740
								? '#EDB700'
								: '#8982A3',
							!deal?.estimated_credit
								? '#5f5f5f'
								: scoreCeil > 740 && scoreCeil < 800
								? '#7ED957'
								: '#625985',
							!deal?.estimated_credit
								? '#6f6f6f'
								: scoreCeil > 799
								? '#008037'
								: '#625985',
						],
						cornerRadius: 0,
						padding: 0.03,
						subArcs: [
							{ limit: 20 },
							{ limit: 40 },
							{ limit: 60 },
							{ limit: 80 },
							{ limit: 100 },
						],
						width: 0.3,
					}}
					pointer={{
						animationDelay: 0,
						color: '#E8E1FF',
						elastic: false,
						length: 0.5,
					}}
					key={'gauge'}
					id="gauge"
					className={
						!deal?.estimated_credit ? 'pointer-modifier' : ''
					}
				/>
			</Suspense>
		);
	};
	return (
		<div className="card sticky max-h-[1300px] bg-[#F9F9F9]  px-[20px] pb-[32px] pt-[20px] xs:col-span-3 md:col-span-1">
			<div className="grid-col-1 grid gap-[36px]">
				<div className="circular-medium text-xl text-[#3b2f66]">
					Deal HUD
				</div>
				<div>
					<button
						disabled={!deal?.estimated_credit}
						className="viewButton analytics-deal-view-button w-[172px] bg-[#9475FF] px-[30px] py-[18px] text-white  disabled:bg-[#EFEBE9] disabled:text-[#BAABA7]"
						onClick={() => toggleDealModal(true)}
					>
						View
					</button>
				</div>
				<div>
					<div className="mb-[6px] flex text-left text-xs font-medium">
						Target Vehicle
					</div>
					<div className="mb-[6px] flex max-w-[365px] truncate text-left text-[18px]">
						<b id="analytics-target-vehicle">
							{targetVehicle?.year
								? `${targetVehicle?.year} ${
										targetVehicle?.make
								  } ${
										targetVehicle?.model &&
										removeHTMLInfo(targetVehicle?.model)
								  }`
								: '-'}
						</b>
					</div>
					<ReactImageFallback
						src={targetVehicle.vehicleImage}
						fallbackImage={placeHolder}
						initialImage={placeHolder}
						alt="deal-vehicle"
						className="car-image-background rounded-[8px] object-cover"
					/>
				</div>
				<hr className="mt-[0px] h-0.5 border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
				{showErrorMessage ? (
					<div className="error-alert">
						<div className="text-[#E70033]">
							<b className="text-[20px] font-bold">
								Please note:
							</b>
							<br />{' '}
							<p className="text-[20px]">
								{errorCodes?.includes(ERRORS.NON_FINANCIBLE)
									? 'The down payment should exceed the minimum downpayment (' +
									  USDollar.format(
											deal?.min_downpayment || 0
									  ) +
									  ').'
									: errorCodes?.includes(
											ERRORS.AMOUNT_TOO_SMALL
									  )
									? 'The down payment should not exceed the maximum downpayment (' +
									  USDollar.format(
											deal?.max_downpayment || 0
									  ) +
									  ').'
									: 'There was an error, try adjusting inputs or target vehicle.'}
							</p>
						</div>
					</div>
				) : showErrorMessage ? (
					<div className="error-alert">
						<div className="text-[#E70033]">
							<p className="text-[20px]">
								There was an error, try adjusting inputs or
								target vehicle.
							</p>
						</div>
					</div>
				) : (
					''
				)}
				{isLoading ? <LoadingOverlay /> : ''}
				<div className="grid grid-flow-col">
					<div>
						<span className="block text-[12px] font-bold text-[#111827]">
							Down Payment
						</span>
						<span
							id="analytics-min-downpayment"
							className="text-[20px] text-[#3B2F66]"
						>
							{deal?.calculated_downpayment
								? `${USDollar.format(
										deal?.calculated_downpayment
								  )}`
								: '-'}
						</span>
					</div>
				</div>
				<div className="grid grid-flow-col">
					<div>
						<span className="block text-[12px] font-bold text-[#111827]">
							Monthly Payment
						</span>
						<span
							id="analytics-max-monthly-payment"
							className="text-[20px] text-[#3B2F66]"
						>
							{deal?.programs?.length &&
							deal?.programs[0].estimated_monthly
								? `${USDollar.format(
										deal?.programs[0].estimated_monthly
								  )} `
								: '-'}
						</span>
					</div>
				</div>
				<div className="grid grid-flow-col">
					<div>
						<span className="block text-[12px] font-bold text-[#111827]">
							Best Rate
						</span>
						<span
							id="analytics-best-rate"
							className="text-[20px] text-[#3B2F66]"
						>
							{deal?.programs?.length && deal?.programs[0].rate
								? `${deal?.programs[0].rate} %`
								: '-'}
						</span>
					</div>
					<div>
						<span className="block text-[12px] font-bold text-[#111827]">
							Term
						</span>
						<span
							id="analytics-term"
							className="text-[20px] text-[#3B2F66]"
						>
							{deal?.programs?.length && deal?.programs[0].term
								? `${deal?.programs[0].term} Months`
								: '-'}
						</span>
					</div>
				</div>
				<div className="grid grid-flow-col">
					<div>
						<span className="block text-[12px] font-bold text-[#111827]">
							Confidence Score
						</span>
						<span
							id="analytics-confidence-score"
							className="text-[20px] text-[#3B2F66]"
						>
							{deal?.confidence?.score || '-'}
						</span>
					</div>
				</div>
				<hr className="mt-[0px] h-0.5 border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />

				<div>
					<span className="block text-[12px] font-bold text-[#111827]">
						Credit Score
					</span>
					<div className="w-full">
						{deal?.estimated_credit ? (
							renderGauge()
						) : (
							<>
								<br />
								<br />
							</>
						)}
					</div>
				</div>
				<div
					className={`-mt-[45px] flex justify-center gap-x-[8px] text-[18px] ${scoreColor}`}
				>
					<p
						id="analytics-credit-score"
						data-credit-score={Math.round(
							deal?.estimated_credit || 0
						)}
					>
						{deal?.estimated_credit
							? `${Math.round(
									deal?.estimated_credit -
										deal?.estimated_credit / 200
							  )}-${Math.round(
									deal?.estimated_credit +
										deal?.estimated_credit / 200
							  )}`
							: 'Enter Customer Information to estimate credit score'}
					</p>
					<p className="font-[600]">
						{!deal?.estimated_credit
							? ''
							: scoreCeil < 580
							? 'Poor'
							: scoreCeil < 670
							? 'Fair'
							: scoreCeil < 740
							? 'Good'
							: scoreCeil < 800
							? 'Very Good'
							: 'Excellent'}
					</p>
				</div>
				<hr className="mt-[0px] h-0.5 border-t-0 bg-[#DDDDDD] opacity-100 dark:opacity-50" />
			</div>
		</div>
	);
};

export default DealHUD;
