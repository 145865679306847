import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import LendersTable from '../../pages/TestingTool/LendersTable';

interface FeedbackButtonProps {
	label?: string;
}

declare global {
	interface Window {
		showCollectorDialog: any;
	}
}

const FeedbackButton = ({ label }: FeedbackButtonProps) => {
	return (
		<>
			<button
				className="viewButton analytics-feedback-button mx-auto flex bg-[#9475FF] px-[30px] py-[10px] text-white"
				onClick={() => window.showCollectorDialog()}
			>
				<IoChatbubbleEllipsesOutline className="my-auto mr-[10px]" />
				{label || 'Provide Feedback'}
			</button>
		</>
	);
};

export default FeedbackButton;
