export const devAPI =
	process.env.NODE_ENV == 'development' ||
	import.meta.env.MODE == 'development'
		? 'https://92k7ilzd9f.execute-api.us-east-1.amazonaws.com/dev'
		: 'https://m5pmyg2l2l.execute-api.us-east-1.amazonaws.com/prod';

export const targetVehicleAPI =
	process.env.NODE_ENV == 'development' ||
	import.meta.env.MODE == 'development'
		? 'https://92k7ilzd9f.execute-api.us-east-1.amazonaws.com/dev'
		: 'https://m5pmyg2l2l.execute-api.us-east-1.amazonaws.com/prod';

export const decisionEngine =
	process.env.NODE_ENV == 'development' ||
	import.meta.env.MODE == 'development'
		? 'https://1pnhtwu741.execute-api.ca-central-1.amazonaws.com/prod/ul'
		: 'https://9hrja8pz2i.execute-api.ca-central-1.amazonaws.com/prod/ul';

export const supportLink =
	'https://agentsupport.carbeeza.com/en/support/tickets/new?ticket_form=onboarding_assistance';

export const CanadaStates = [
	{ code: 'AB', state: 'Alberta' },
	{ code: 'BC', state: 'British Columbia' },
	{ code: 'MB', state: 'Manitoba' },
	{ code: 'NB', state: 'New Brunswick' },
	{ code: 'NL', state: 'Newfoundland and Labor' },
	{ code: 'NT', state: 'Northwest Territories' },
	{ code: 'NS', state: 'Nova Scotia' },
	{ code: 'NU', state: 'Nunavut' },
	{ code: 'ON', state: 'Ontario' },
	{ code: 'PE', state: 'Prince Edward Island' },
	{ code: 'QC', state: 'Quebec' },
	{ code: 'SK', state: 'Saskatchewan' },
	{ code: 'YT', state: 'Yukon' },
];

export const UnitedStates = [
	{ code: 'AL', state: 'Alabama' },
	{ code: 'AK', state: 'Alaska' },
	{ code: 'AZ', state: 'Arizona' },
	{ code: 'AR', state: 'Arkansas' },
	{ code: 'CA', state: 'California' },
	{ code: 'CO', state: 'Colorado' },
	{ code: 'CT', state: 'Connecticut' },
	{ code: 'DE', state: 'Delaware' },
	{ code: 'FL', state: 'Florida' },
	{ code: 'GA', state: 'Georgia' },
	{ code: 'HI', state: 'Hawaii' },
	{ code: 'ID', state: 'Idaho' },
	{ code: 'IL', state: 'Illinois' },
	{ code: 'IN', state: 'Indiana' },
	{ code: 'IA', state: 'Iowa' },
	{ code: 'KS', state: 'Kansas' },
	{ code: 'KY', state: 'Kentucky' },
	{ code: 'LA', state: 'Louisiana' },
	{ code: 'ME', state: 'Maine' },
	{ code: 'MD', state: 'Maryland' },
	{ code: 'MA', state: 'Massachusetts' },
	{ code: 'MI', state: 'Michigan' },
	{ code: 'MN', state: 'Minnesota' },
	{ code: 'MS', state: 'Mississippi' },
	{ code: 'MO', state: 'Missouri' },
	{ code: 'MT', state: 'Montana' },
	{ code: 'NE', state: 'Nebraska' },
	{ code: 'NV', state: 'Nevada' },
	{ code: 'NH', state: 'New Hampshire' },
	{ code: 'NJ', state: 'New Jersey' },
	{ code: 'NM', state: 'New Mexico' },
	{ code: 'NY', state: 'New York' },
	{ code: 'NC', state: 'North Carolina' },
	{ code: 'ND', state: 'North Dakota' },
	{ code: 'OH', state: 'Ohio' },
	{ code: 'OK', state: 'Oklahoma' },
	{ code: 'OR', state: 'Oregon' },
	{ code: 'PA', state: 'Pennsylvania' },
	{ code: 'RI', state: 'Rhode Island' },
	{ code: 'SC', state: 'South Carolina' },
	{ code: 'SD', state: 'South Dakota' },
	{ code: 'TN', state: 'Tennessee' },
	{ code: 'TX', state: 'Texas' },
	{ code: 'UT', state: 'Utah' },
	{ code: 'VT', state: 'Vermont' },
	{ code: 'VA', state: 'Virginia' },
	{ code: 'WA', state: 'Washington' },
	{ code: 'WV', state: 'West Virginia' },
	{ code: 'WI', state: 'Wisconsin' },
	{ code: 'WY', state: 'Wyoming' },
];
